@import "~react-image-gallery/styles/css/image-gallery.css";


html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

:root {
  --font-family: 'Roboto', sans-serif;
  --admin-header-height: 80px;
  --admin-sidebar-width: 250px;
  --negative-item-color: #DE6360;
  --negative-hover-color: #bb4e4c;
  --intermediate-item-color: #E39737;
  --intermediate-hover-color: #e68510;
  --positive-item-color: #41C9D1;
  --positive-hover-color: #27a7ad;
  --neutral-item-color: #2196f1;
  --neutral-hover-color: #087ed8;
  --main-header-height: 70px;
}


a {
  text-decoration: none;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

a:focus:hover:visited:link:active {
  text-decoration: none;
}

/* Remove number input arrows */
/* Chrome, Safari, Edge, Opera */


/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.Toastify__toast {
  outline: none;
  border: 2px solid #c4c4c4;
  transition: ease 0.5s;
  border-radius: 10px;
}



/*------ GLOBALSTYLE START ------*/

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}


.formInput{
  margin: 10px;
  transition: ease 0.5s;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
}

.formInput:focus {
  outline: none;
  border: 2px solid #000000;
  transition: ease 0.5s;
}

.formInstruction {
  margin-top: 0;
  font-size:13px;
}

.errorInstruction {
  margin-top: 0;
  font-size:13px;
  color: #ff5858;
}


.invalidInput {
  border: 2px solid #ff5858;
}

.formButton{
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 5px;
  font-family: var(--font-family);

}

.formButton:disabled{
  background-color: #c4c4c4;
  color: #ffffff;
  font-weight: 700;
  transition: ease 0.2s;
}

.formButton:hover{
  cursor: pointer;
}



/*------ GLOBALSTYLE START ------*/

/*-------<LoadingState Style Start>------*/
@keyframes loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.detailLoading {
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #ffffff 50%, #eceff1 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
  border: none;
  margin-bottom: 5px;
}

.cardLoading {
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #ffffff 50%, #eceff1 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
  border-radius: 20px;
  width: 350px;
  height: 300px;
  margin: 5px 2px;
  margin-bottom: 20px;
  /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25); */
}


.loadingCardContainer {
  margin: 20px 0 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-template-rows: auto;
  justify-items: center;
}



/*-------<LoadingState Style END>------*/


/*------ Login START ------*/

.loginContainer {
  background-color: #f1f1f1;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginWrapper {
  height: 500px;
  font-family: var(--font-family);
  font-size: 15px;
}

.loginHomeLink{
  color: gray;
  font-family: var(--font-family);
  margin: 15px;
  text-align: center;
}

.loginHomeLink:hover{
  color: black;
  font-family: var(--font-family);
  margin: 15px;
  text-align: center;
}

.loginCard p {
  color: gray;
  font-family: var(--font-family);
}

.loginCard p:hover {
  color: black;
}

.loginCard {
  color: #000000;
  background-color: #ffffff;
  height: 300px;
  width: 300px;
  border-radius: 20px;
  font-family: var(--font-family);
  flex-direction:column;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  padding-top: 30px;
}

.errorCard{
  color: #575757;
  background-color: #fdc4c4bd;
  height: 60px;
  width: 300px;
  border-radius: 20px;
  font-family: var(--font-family);
  flex-direction:column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding:5px;
  margin-bottom: 10px;
}

.errorCard p {
  font-family: var(--font-family);
}

.loginImage {
  width: 300px;
  display: flex;
  justify-content: center;
}

.loginImage img {
  width: 100px;
  height: 100px;
  margin: 20px;
}

.loginInputFieldGroup {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin: 10px 10px 0 10px;
  padding: 0 10px 0 10px;
}

.loginInputFieldGroup label {
  font-family: var(--font-family);
  color: #000000;
  font-size: 15px;
  margin: 0 0 5px 1px;
}

.loginInput {
  margin: 0 0 20px 0;
}

.loginButtonRow{
  margin: 0 10px 0 10px;
  padding: 10px;
  display: flex;
}

.loginButton {
  background-color: #41C9D1;
  color: #ffffff;
  font-weight: 700;
  margin-right: 10px;
}

.loginButton:hover{
  background-color: #27a7ad;
  color: #ffffff;
  font-weight: 700;
  margin-right: 10px;
  transition: ease 0.2s;
}

.logoutButton {
  background-color: #DE6360;
  color: #ffffff;
  font-weight: 700;
  margin-right: 10px;
}

.logoutButton:hover {
  background-color: #bb4e4c;
  color: #ffffff;
  font-weight: 700;
  transition: ease 0.2s;
}

.loginButtonRow p {
  font-size: 12px;
  align-self: flex-end;
}


/*------ Login END ------*/

/*------ Verify START ------*/

.verifyCard {
  color: #000000;
  background-color: #ffffff;
  height: 200px;
  width: 300px;
  border-radius: 20px;
  font-family: var(--font-family);
  flex-direction:column;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  padding-top: 30px;
}

.verifyCardWrap{
  height: 300px;
  width: 300px;
  border-radius: 20px;
  background: linear-gradient(45deg, #902EC6, #D45C6E);
  box-shadow: 0px 4px 20px rgba(255, 255, 255, 0.19);
}

.verifyIcon{
  width: 70px;
}

.verifyCard h1 {
  padding: 10px 0 0 0;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
}
/*------ Verify END ------*/


/*------ Reset START ------*/
.resetPasswordHeading{
  text-align: center;
  font-size: 20px;
  font-weight: 900;
}

/*------ Reset END ------*/
/*------ Amenities START ------*/

.amenitiesContainer{
  background-color: #273f83;
  display: flex;
}


.amenitiesForm{
  display: flex;
}

.amenitiesFormLabel{
  background-color: black;
  font-size: medium;
}

.amenitiesFormInput{
  background-color: #D45C6E;
  transition: ease 0.5s;
  border-bottom: 2px solid #c4c4c4;
}

.amenitiesFormInput:focus {
  outline: none;
  border-bottom: 2px solid #555555;
  transition: ease 0.5s;
}


.resetButtonRow {
  margin: 0 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.resetButtonRow button{
  justify-self: center;
}

/*------ Reset END ------*/

/*------ AdminContainer START ------*/

.adminContainer {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #f1f1f1
}

/*------ AdminContainer END ------*/


/*------ AdminContentWrapper START ------*/

.adminContentWrapper {
  margin-top: var(--admin-header-height)
}

/*------ AdminContentWrapper END ------*/

/*------ AdminSidebar START ------*/


.sideBar{
  height: 100%;
  width: var(--admin-sidebar-width);
  position: fixed;
  overflow-x: hidden;
  z-index: 1;
}

.sidebarItem{
  display: flex;
  flex-direction: row;
  margin: 10px 10px 10px 10px;
  justify-content: flex-start;
  align-items: center;
  color: #8f8d8d;
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
}

.sidebarItem:hover {
  background: #e0e0e0;
  transition: ease 0.5s;
  color: #859aa5;
  cursor: pointer;
}


.sidebarIconWrapper{
  margin-top: 2px;
  width: 25px;
}

.sidebarItem i {
  font-size: 20px;
}


.sidebarTextWrapper {
  margin: 0 0 0 0;
  width: 100px;
}

.sidebarTextWrapper h1 {
  font-size: 13px;
  font-weight: 700;
  align-self: flex-start;
}





/*------ AdminSidebar END ------*/


/*------ AdminHeader START ------*/

.adminHeader{
  margin: 0 0 0 0;
  width: 100%;
  height: var(--admin-header-height);
  background: #181e2e;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  background: linear-gradient(150deg, #4ce974 0%, #006eff 35%, #3131cb 100%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  z-index: 5;
}

.adminHeaderStart {
  justify-self: flex-start;
  color: #ffffff;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.adminHeaderTitle {
  align-self: center;
}

.adminHeaderTitle h1{
  margin-left: 20px;
  font-size: 25px;

}

.adminHeaderLogo {
  height: 50px;
  width: 50px;
  align-self: center;
}

.adminHeaderLogo img{
  height: 50px;
  width: 50px;
  align-self: center;
}

.adminHeaderEnd{
 justify-self: flex-end;
 color: #ffffff;
 width: 500px;
 display: flex;
 flex-direction: column;
 justify-content: center;
}

.headerUserName{
  margin-left: auto;
  display: flex;
  justify-content: space-evenly;
  width: 300px;
  margin-right: 50px;
}


.headerButton{
  background: none;
  color: #ffffff;
  border: solid 1px #ffffff;
  font-weight: 700;
  width: 150px;
  width: 200px;
}

.headerButton:hover{
  background: #ffffff;
  color: #181e2e;
  border: solid 1px #ffffff;
  transition: ease 0.2s;
}

.headerUserName h1 {
  margin: 0 0 0 0;
  font-weight: 400;
  font-family: var(--font-family);
  font-size: 15px;
  align-self: center;
  width: 500px
}


/*------ AdminSidebar END ------*/


/*------ AdminCard START ------*/
.adminContent {
  margin-left: var(--admin-sidebar-width);
  height: 100%;
  max-width: 100%;
  background: #f1f1f1;
}

.adminActionBar{
  display: flex;
  flex-direction: row;
  margin: 10px 20px 10px 20px;
  font-family: var(--font-family);
  justify-content: space-between;
}

.actionTitle{
  display: flex;
  flex-direction: column;
  color: gray;
}

.actionTitle h1{
  font-size: 20px;
  font-weight: 900;
  margin: 10px 5px;
}

.actionTitle h2{
  font-size: 13px;
  font-weight: 400;
  margin: 0 5px;

}

.actionEnd{
  display: flex;
  flex-direction: row;
}



.adminAction{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--neutral-item-color);
  border-radius: 10px;
  color: #ffffff;
  font-weight: 700;
  font-size: 13px;
  padding: 0 10px 0 10px;
  margin: 5px;
  height: 35px;
}

.adminAction i {
  font-size: 25px;
  margin: 5px;
  align-self: center;
}

.adminAction p {
  margin: 5px;
  align-self: center;
}

.adminAction:hover{
  background-color: var(--neutral-hover-color);
  cursor: pointer;
  transition: ease 0.2s;

}

.listItemActionsBar {
  display: flex;
  justify-content: flex-end;
}

.editAction {
  background-color: var(--neutral-item-color);
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 5px;
  margin-right: 5px;
}

.editAction:hover {
  background-color: var(--neutral-hover-color);
  transition: ease 0.2s;
  cursor: pointer;
}

.deleteAction {
  background-color: var(--negative-item-color);
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 5px;

}

.deleteAction:hover {
  background-color: var(--negative-hover-color);
  transition: ease 0.2s;
  cursor: pointer;
}


.restoreAction {
  background-color: var(--positive-item-color);
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 5px;
  margin-right: 5px;

}

.restoreAction:hover {
  background-color: var(--positive-hover-color);
  transition: ease 0.2s;
  cursor: pointer;
}




/*------ AdminCard END ------*/


/*------ Users START ------*/

.userContainer{
  width: 100%;
  height: 100%;
  margin: 0 20px 0 0;
  display: flex;
  flex-direction: column;

}

.userContainer h1 {
  margin:  0 0 0 0;
}

.userItem{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  margin: 10px 20px 10px 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  height: 100px;
}

.disabledUserItem{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  margin: 10px 20px 10px 20px;
  background: #ebebeb;
  border-radius: 10px;
  height: 100px;
}

.userEmailGroup{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-width: 200px;
}

.userEmail{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.userNameGroup{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-width: 150px;
}


.userLevel{
  display: flex;
  flex-direction: column;
}

.userLevel p {
  margin-right: 5px;
  margin-bottom: 10px;
}

.userLevelTag{
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 6px;
  min-width: 110px;
  height: 30px ;
  align-self: center;

}

.levelOne{
  background-color: #EBBA1C;
}

.levelTwo{
 background-color: #1E8CD9;
}

.levelThree{
  background-color: #791EF0;
}

.disabledTag{
  background-color: #c4c4c4;
}


.userLevelTag p {
  align-self: center;
  color: #ffffff;
  font-family: var(--font-family);
  font-weight: 700;
}

.userLabel{
  font-family: var(--font-family);
  font-weight: 700;
}

.verifiedIcon{
  margin: 1px 0 0 5px;
}

.verifiedIcon i{
  color: #228B22;
}

.addUserItem{
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0 20px;
  margin: 10px 20px 10px 20px;
  border-radius: 10px;
  height: 70px;
  border: grey dotted 5px;
  transition: ease 0.5s;
}

.addUserItem:hover {
  background-color: #c4c4cc;
  cursor: pointer;
  transition: ease 0.5s;
}

.addUserItemExpanded {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0 20px;
  margin: 10px 20px 10px 20px;
  border-radius: 10px;
  min-height: 300px;
  background: #ffffff;
  border: grey dotted 5px;
  transition: ease 0.5s;

}

.addUserItemExpanded h1 {
  color: #000000;
  transition: ease 1s;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  transition: ease 0.5s;
}

.addUserItem h1 {
  color: grey;
  transition: ease 1s;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: ease 0.5s;
}

.userFormContainer{
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: ease 0.5s;

}

.userFormButtonRow{
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: auto;
  margin-right: 10px;
}

.saveButton{
  background-color: #41C9D1;
  color: #ffffff;
  font-weight: 700;
  margin-right: 10px;
}

.saveButton:hover{
  background-color: #27a7ad;
  color: #ffffff;
  font-weight: 700;
  margin-right: 10px;
  transition: ease 0.2s;
}

.cancelButton{
  background-color: #DE6360;
  color: #ffffff;
  font-weight: 700;
}

.cancelButton:hover{
  background-color: #bb4e4c;
  color: #ffffff;
  font-weight: 700;
  transition: ease 0.2s;
}

.userItemButtonRow {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.deleteButton{
  background-color: #DE6360;
  color: #ffffff;
  font-weight: 700;
}

.deleteButton:hover{
  background-color: #bb4e4c;
  color: #ffffff;
  font-weight: 700;
  transition: ease 0.2s;
}

.disableButton{
  background-color: #E39737;
  color: #ffffff;
  font-weight: 700;
}

.disableButton:hover{
  background-color: #e68510;
  color: #ffffff;
  font-weight: 700;
  transition: ease 0.2s;
}


.enableButton{
  background-color: #41C9D1;
  color: #ffffff;
  font-weight: 700;
}

.enableButton:hover{
  background-color: #27a7ad;
  color: #ffffff;
  font-weight: 700;
  transition: ease 0.2s;
}

.userInput {
  width: 50%;
}

.userFieldGroup{
  display: flex;
}

.userFieldGroup select {
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  -webkit-box-sizing:content-box;
  box-sizing:content-box;
}

.validationText{
  color: #ff5858;
  font-family:  var(--font-family);
  margin-left: 10px;

}

/*------ Users END ------*/


/*------ properties START ------*/

.mapWrapper {
  overflow: hidden;
  border-radius: 20px;
}

.amenityPickerCategory {
  font-weight: 600;
  font-size: 15px;
  margin-left: 10px;
}

.propertiesContainer {
  max-width: 100%;
  height: 100%;
  margin: 0 20px 0 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.propertiesFormRowGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
}

.propertiesFormSection {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px 20px;
  margin: 10px 20px 10px 20px;
  border-radius: 10px;
  min-height: 350px;
  background: #ffffff;
  transition: ease 0.5s;
  font-family: var(--font-family);
  font-size: 15px;
  color: gray;
}

.propertiesFormSection h1{
  font-family: var(--font-family);
  margin: 5px 10px 15px 10px;
  font-size: 20px;
  font-weight: 700;
}

.propertiesFormColumnPimary{
  display: flex;
  flex-direction: column;
  width: 65%;
}

.propertiesFormColumnSecondary{
  display: flex;
  flex-direction: column;
  width: 35%;
}

.propertiesFormSection form {
  width: 100%;
  margin: 0;
  padding: 0;

}

.propTextArea {
  resize: none;
  font-family: var(--font-family);
  height: 200px;
}

.propAddressArea {
  resize: none;
  font-family: var(--font-family);
  height: 50px;
}

.propFormButtonRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: auto;
  margin: 20px 10px;
  flex-wrap: wrap;
}


.propFormButtonRow button {
  width: 400px;
  margin: 5px 0;
}

.publishButton {
  background-color: var(--positive-item-color);
  color: #ffffff;
  font-weight: 700;
}

.publishButton:hover {
  background-color: var(--positive-hover-color);
  cursor: pointer;
  transition: ease 0.2s;
}

.saveDraftButton {
  background-color: var(--neutral-item-color);
  color: #ffffff;
  font-weight: 700;
}

.unpublishButton{
  background-color: #E39737;
  color: #ffffff;
  font-weight: 700;
}

.unpublishButton:hover{
  background-color: #e68510;
  color: #ffffff;
  font-weight: 700;
  transition: ease 0.2s;
}
.saveDraftButton:hover {
  background-color: var(--neutral-hover-color);
  cursor: pointer;
  transition: ease 0.2s;
}

.propertiesFormSection label {
  margin: 5px 10px 5px 10px;

}

.propertiesTitleInput {
  font-size: 20px;
}

.featuredRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.propFieldGroupBi {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.propInputWrapperBi{
  width: 100%
}

.propertiesFilter{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 20px 0 20px;
  margin: 10px 20px 10px 20px;
  background: #f9f9f9;;
  border-radius: 10px;
}

.propertiesList{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 20px 0 20px;
  margin: 10px 20px 10px 20px;
  background: #ffffff;
  border-radius: 10px;
}

.propertiesList table{
  font-family: var(--font-family);
  justify-content: space-between;
  margin: 10px 20px;
  width: 100%;
}

.propertiesList tr {
  justify-content: space-between;
  width: 100%;
}

.propertiesList th {
  text-align: start;
}

.propertiesList td {
  padding: 20px 0 20px 0;
  font-size: 15px;

}

.propertyStatusTag {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0 5px;
  margin: 5px 10px;
  border-radius: 10px;
  max-width: 100px;
  height: 30px ;
  margin-right: 10px;
}

.propertyStatusTag p {
  align-self: center;
  color: #ffffff;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 15px;
}

.newTag{
  background-color: #c4c4c4;
}

.publishedTag{
  background-color: var(--positive-item-color);
}

.draftTag{
  background-color: var(--neutral-item-color);
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 1px;
  right: 0px;

  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--positive-item-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--positive-item-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.propTypeContainer{
  width: 100%;
  height: 100%;
  margin: 0 20px 0 0;
  display: flex;
  flex-direction: column;
}


.propTypeList{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0 20px 0 20px;
  margin: 10px 20px 10px 20px;
  background: #ffffff;
  border-radius: 10px;
}


.propTypeList table{
  font-family: var(--font-family);
  justify-content: space-between;
  margin: 10px 20px;
  width: 100%;
  border-collapse: collapse;
}

.propTypeList tr {
  width: 100%;
  color: #747a80;;

}

.propTypeList tr:hover {
  background-color: #fafafa;
  cursor: pointer;
}

.propTypeList th {
  text-align: start;
  color: #000000;
  padding: 10px 0 10px 0;

}

.propTypeList td {
  border-top: #e4e4e4 solid 1px;
  padding: 10px 0 10px 0;
  font-size: 15px;
  height: 50px;

}

.propTypeFormContainer {
  display: flex;
  flex-direction: column;
  padding: 5px 20px 10px 20px;
  margin: 10px 20px 10px 20px;
  border-radius: 10px;
  min-height: 350px;
  background: #ffffff;
  transition: ease 0.5s;
  font-family: var(--font-family);
  font-size: 15px;
  color: gray;
}

.propTypeInput {
  resize: none;
  font-family: var(--font-family);
  width: 50%;
}

.propTypeTextArea {
  resize: none;
  font-family: var(--font-family);
  width: 95%;
  height: 100px;
}

.propTypeFieldGroup{
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.propTypeFieldGroup label{
  margin: 5px 10px 5px 10px;
}

.holTypeImage {
  max-width: 100%;
  max-height: 100%;
  display: block;

}

.uploadRow {
  display: flex;
  flex-direction: row;
}

.fileLabel {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  border-radius: 20px;
  padding: 5px 15px 5px 15px;
  margin: 20px 0 20px 0;
}


.substituteImage {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.substituteLabel {
  font-family: var(--font-family);
  font-weight: 700;
  color: #ffffff;
  background: var(--positive-item-color);
  border-radius: 5px;
  margin: 50px 0 20px 0;
  align-self: center;
  padding: 10px 15px
}

.substituteLabel:hover {
  background: var(--positive-hover-color);
  cursor: pointer;
  transition: ease 0.2s;
}

.subInstruction {
  margin: 5px 10px 5px 10px;
}

.uploadRow {
  margin-bottom:10px;
}

.fileLabel {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  border-radius: 20px;
  padding: 5px 15px 5px 15px;
  margin: 20px 0 20px 0;
  align-self: center;
}

.holTypeImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  height:200px;
  margin: 20px 10px 10px 10px;
  padding: 10px
}

.listItemImageContainer{
   display: flex;
   flex-direction: row;
}

.listItemImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70px;
  height: 70px;
  font-size: 35px;

}


.listItemImageWrapper img {
  max-width: 100%;
  max-height: 100%;
}

.listItemTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 10px;

}

.locationsListContainer {
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 20px;
  margin: 10px 20px 10px 20px;
  background: #ffffff;
  border-radius: 10px;
}

.locationsMenu{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-start;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 13px;
  margin: 10px 0;

}

.locationsMenu h3 {
  margin: 10px 5px;
  color: #8f8d8d;
  padding: 0 5px;

}

.locationsMenu h3:hover {
  color: var(--neutral-item-color);
  cursor: pointer;
  transition: ease-in 0.3s;
  border-bottom: solid 3px var(--neutral-item-color);
}

.locationsMenuActive {
  color: var(--neutral-item-color);
  border-bottom: solid 3px var(--neutral-item-color);
}


.countriesFormContainer {
  display: flex;
  flex-direction: column;
  padding: 5px 20px 10px 20px;
  margin: 10px 20px 10px 20px;
  border-radius: 10px;
  min-height: 150px;
  background: #ffffff;
  transition: ease 0.5s;
  font-family: var(--font-family);
  font-size: 15px;
  color: gray;
}

.countriesFieldGroup{
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 10px;
}

.countriesFieldGroup label{
  margin: 5px 10px 5px 10px;
}

.countriesInputWrapper {
  display: flex;
  flex-direction: column;
  width: 50%
}

.countryNameInput {
  width: 90%;
}

.countryCodeInput {
  width: 84%;
}

.atollsFieldGroupFull {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-right: 10px

}

.atollsFieldGroupFull label{
  margin: 5px 10px 5px 10px;
}

.atollsInputWrapperFull {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.atollCountrySelectWrapper {
  margin: 10px 10px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
}
.atollCountrySelectWrapper:focus {
  border: 1px solid #000000;
  transition: ease 0.2s;
}

.atollsInputWrapperTri{
  display: flex;
  flex-direction: column;
  width: 33.3%;
}

.islandInputWrapperBi {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.invalidSelectInput {
  border: 2px solid #ff5858;
}

.cordNumber{
  width: 84%
}

.cordNumber::-webkit-outer-spin-button,
.cordNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.imageBatchContainer {
  width: 100%;
}

.propertiesBatchImageRowGroup {
  width: 100%;
}

.batchImageDisplayArea{
  width: 90%;
  height: 250px;
  padding: 10px;
  overflow-y: scroll;
}

.batchImageGridCContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 2fr));
  grid-auto-rows: 150px;
  justify-items: center;
}

.imageElement {
  position: relative;
  background: skyblue;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  border: 5px solid #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  transition: ease 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.imageElement:hover {
  width: 105px;
  height: 105px;
  transition: ease 0.3s;
  cursor: pointer;
}

.imageContainer {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageContainer img {
  display: block;
  width: 180%;
  height: auto;
  align-self: center;

}

.imageOverlay {
  border-radius: 5px;
  position: absolute;
  display: block;
  height: 105px;
  width: 105px;
  opacity: 0;
  transition: .3s ease;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageElement:hover .imageOverlay {
  opacity: 1;
}

.imageIcon {
  position: absolute;
  color: red;
  height: 20px;
  border-radius: 100%;;
  background-color: white;
  margin: 5px;
  font-size: 20px;
  text-align: center;
  align-self: center;
  justify-self: center;
}

.roomTypeListContainer{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.addRoomButton{
  background-color: #41C9D1;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  margin-bottom: 10px;
}

.addRoomButton:hover{
  background-color: #27a7ad;
  transition: ease 0.2s;
}

.roomTypeFormContainer{
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.roomTypeFormContainer p{
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 5px;


}

.roomTypeInput {
  width: 85%;
}

.roomTypeFormButtonRow{
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: auto;
  margin-right: 10px;
  width: 100%;
}

.roomDeleteAction {
  background-color: var(--negative-item-color);
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 2px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 5px;
}

.deleteAction {
  background-color: var(--negative-item-color);
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 5px;

}

.deleteAction:hover {
  background-color: var(--negative-hover-color);
  transition: ease 0.2s;
  cursor: pointer;
}

.roomtypeFormSection{
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px 20px;
  margin: 10px 20px 10px 20px;
  border-radius: 10px;
  min-height: 150px;
  background: #ffffff;
  transition: ease 0.5s;
  font-family: var(--font-family);
  font-size: 15px;
  color: gray;
  width: 100%;
}

.roomtypeFormSection label {
  margin: 5px 10px 5px 10px;

}

.roomtypeFormSection h1{
  font-family: var(--font-family);
  margin: 5px 10px 15px 10px;
  font-size: 20px;
  font-weight: 700;
}

.roomTypeList{
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  margin: 10px 20px 10px 20px;
  background: #ffffff;
  border-radius: 10px;
  width: 100%
}

.roomTypeSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 20px 20px 10px 20px;
  margin: 10px 20px 10px 20px;
  border-radius: 10px;
  min-height: 150px;
  background: #ffffff;
  font-family: var(--font-family);
  font-size: 15px;
  color: gray;
  width: 100%;
}

.roomTypeSection h1{
  font-family: var(--font-family);
  margin: 5px 10px 15px 10px;
  font-size: 20px;
  font-weight: 700;
}



/*------ properties END ------*/

/*------ moreInfoShared START ------*/


.moreInfo{
  display: flex;
  flex-direction: row;
}

.moreInfo i {
  margin: 0 0;
  padding: 0 0;
  font-size: 20px;
}

.moreInfo p {
  margin: 0 0;
  padding: 0 0;
  font-size: 15px;
}

.moreInfo p:hover{
  text-decoration: underline;
  cursor: pointer;
}


/*------ moreInfoShared END ------*/



/*------ HeaderShared START ------*/

.vl {
  border-left: 1px solid #c4c4c4;
  height: 10px;

}

.header{
  width:100%;
  height: var(--main-header-height);
  position:fixed;
  top:0px;
  z-index:3;
  transition: ease 0.5s;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 4;
}

.headerActive.active{
  background-color: rgba(151, 151, 151, 0.384);
  transition: ease 0.5s;
}

.headerLogo{
  display: flex;
  flex-direction: row;
  justify-self: start;
  width: 400px;
  padding: 5px 40px;
}

.headerLogo img{
  display: block;
  max-width: 100%;
  max-height: 90%;
}

.headerNav {
  display: flex;
  flex-direction: column;
  width: 45%;
  align-content: center;
  justify-content: center;
}

.headerNav ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-weight: 700;
  margin-right: 20px;
}

.headerNav li {
  text-align: center;
  padding: 25px 10px;
  width: 100%;
}

.headerNav a {
  text-decoration: none;
  color: #000000;
}

.headerNav li:hover {
  cursor: pointer;
  color: #ff6600;
  transition: ease 0.2s;
}

.mobileMenu {
  display: flex;
  flex-direction: row;
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.mobileMenu a{
  text-decoration: none;
  color: #085373;
}

.mobileNavigation {
  background: url("/resources/mobileMenuBack.jpeg");
  background-size: contain;
  background-color: #085373c7;
  height: 100vh;
  width: 70%;
  margin-left: auto;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.mobileNavigationItems {
  border-radius: 100px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  color: #085373;
  margin-top: 150px;
  overflow: hidden;
  
}

.mobileNavigationItemWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 50%;
  background-color: #ffffff;
  color: #085373;
  margin-top: 40px;
  margin-bottom: auto;
}

.navHeader {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  color: #085373;

}

.navItem {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-family: 15px;
  color: #085373;

}

.mobileAction {
  height: 100vh;
  width: 30%;
}


.caret {
  height: 100%;
  text-align: center;
  padding: 0;
}

.ri-arrow-drop-down-line:before{
  content:none;
}

.subNavContainerInactive {
  display: none;

}

.subNavContainer {
  position: relative;
  display: none;
}

.headerNav li:hover .subNavContainer {
  display: inline;
}

.subNavContent {
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 16px;
  border: none;
  outline: none;
  padding: 14px 16px;
  background-color: #ffffff;
  position: absolute;
  margin: 25px 0 0 0;
  width: 120px;
  min-height: 160px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 5px 5px;
  z-index: 1;
}

.subNavContainer a {
  text-decoration: none;
  align-self: center;
  justify-self: center;
  text-align: center;
  color: #000000;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.subNavContainer a:hover {
  text-decoration: none;
  cursor: pointer;
  color: #ff6600;
  transition: ease 0.2s;
  background-color: #ececec;
}


@keyframes expand{
  0%{height:0px}
  100%{height:100%}
}

.mobileMenuWrapper ul{
  top: 0;
  right: 0;
  height: 80%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  justify-content: space-evenly;
  font-weight: 700;
  font-size: 20px;
  transition: ease 0.8s;
  /* animation: expand 0.5s; */
}

.mobileMenuWrapper li {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mobileMenuWrapper li p{
  margin: 0;
}

.mobSubNavContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  animation-name: expand;
}

.mobSubNavLink{
  margin: 5px;
}

.mobSubNavContent {
  /* height: 150px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.mobSubNavContent a{
  color: #ffffff;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {

  .mobileMenuWrapper {
    max-width: 100%;
  }

  .header {
    max-width: 100%;
  }

  .headerLogo{
    padding: 5px 20px;
  }

  .headerNav{
    display: none;
  }

}

/*------ Headershared END ------*/


/*------ Hamburger START ------*/

.hamburgerNav{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
}

#nav-icon2{
  width: 60px;
  height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 5px;
  width: 50%;
  background:#000000;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 18px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 36px;
}

#nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #ffffff;
}

#nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #ffffff;

}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}

@media only screen and (min-width: 600px) {
  .hamburgerNav{
    display: none;
  }
}


/*------ Hamburger END ------*/


/*------ Landingpage START ------*/

.sectionWrapper{
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}

.aboutSection{
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.altSection{
  background-color:#fdf8f2;
}

.image-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.image-container img {
  display: block;
  max-width: 100%;
  max-height: 100vh;
}

.introTextContainer {
  background: #00000036;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 3;
}

.introTextContainer h1 {
  align-self: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 700;
  font-size: 50px;
  color: #ffffff;
  padding: 0 30px;

}

.introTextContainer div {
  display: inline;
  color: #ff8400 ;
  padding: 5px;
}

.introTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
  width: 400px;
  height: 300px;
}

.introAction {
  background: none;
  color: #ffffff;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 20px;
  margin: 0 30px;
  border:#ffffff solid 2px;
}

.introAction:hover {
  background-color: #ffffff;
  color: #000000;
  transition: ease 0.3s;
  cursor: pointer;
}

.aboutSection a{
  margin: 0;
  padding: 0;
}

.aboutAction {
  background: none;
  color: #ffffff;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 20px;
  border:#ffffff solid 2px;
}

.aboutAction:hover {
  background-color: #ffffff;
  color: #000000;
  transition: ease 0.3s;
  cursor: pointer;
}

.aboutSection img{
  width: 100%;
  height: 100%;
}

.aboutTextContainer {
  background: #00000036;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 3;
}


.aboutTextWrapper{
  width: 60%;
  /* padding: 50px 50px; */
  margin-left: 50px;
  margin-bottom: 50px;
}

.aboutTextWrapper h1 {
  width: 50%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 40px;
  color: #ffffff;
  padding-top: 40px;
  padding-bottom: 10px;
}

.aboutTextWrapper p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: justify;
}

.carouselSection{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
  background: linear-gradient(150deg, #f9fcff 2%, #e1fdf885 50%);
  overflow: hidden;
  height: 100%;

}


.carouselSection h1 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.carouselContainer{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-template-rows: auto;
  justify-items: center;
  min-width: 50%;

}

.paginateContainer {
  display: grid;
  grid-template-columns: 50px auto 50px;
  align-items: center;
  min-height: 350px;
  overflow: hidden;

}

.paginateActive {
  font-size: 50px;
}

.paginateActive:hover{
  font-size: 55px;
  transition: ease 0.5s;
  cursor: pointer;
}

.paginateInactive {
  color: gray;
  font-size: 50px;

}

.paginateInactive:hover {
  color: gray;
  font-size: 50px;

}

.propertyCardContainer{
  display: flex;
  background-color: royalblue;
  width: 350px;
  height: 300px;
  border-radius: 20px;
  margin: 5px 2px;
  transition: transform 300ms ease 100ms;
  margin-bottom: 20px;
  overflow: hidden;
}

.propertyCardContainer img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.propertyCardContainer:hover {
  transform: scale(1.2) !important;
  cursor: pointer;
}

.cardInfo{
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 150px;
  position: absolute;
  align-self: flex-end;
  background-color: rgba(70, 70, 70, 0.514);
  border-radius: 0 0 20px 20px;
  color: #ffffff;
  text-align: left;
}

.cardInfo p {
  font-family: var(--font-family);
  font-weight: 700;
  padding: 0 20px;
  font-size: 20px;
  text-align: left;
}

.propRating{
  margin: 0 20px;
}

.propRating i{
  color: rgb(255, 217, 0);
  font-size: 20px;
}


@media only screen and (max-width: 600px) {

  .sectionWrapper{
    height: 100%;
    width:100%;
    overflow: hidden;
  }

  /* .introAction {
    display: none;
  } */

  .image-container {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 300px;
    overflow: hidden;
  }

  .image-container img {
    display: block;
    max-width: 500px;
  }

  .introTextWrapper{
    border: none;
    align-content: center;
    justify-content: center;
  }

  .introTextWrapper div {
    color: #ff8400;
  }

  .introTextContainer {
    height: 300px;
    width: 100%;
    background: none;
  }

  .aboutTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .aboutTextWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
  }

  .aboutTextContainer h1 {
    font-size: 30px;
  }
  .aboutTextContainer p {
    font-size: 20px;
  }

  .introTextContainer {
    margin-top: var(--main-header-height);
    display:block;
    width: 100%;
    height: 230px;
    background: #00000036;

  }

  .introTextContainer h1 {
    font-size: 30px;
  }

  .aboutSection{
    height: 100vh;
    overflow: hidden;
  }

  .aboutSection img{
    min-height: 100%;
    width: 200%;
  }

  .aboutTextContainer {
    height: 100vh;

  }

  .aboutTextWrapper {
    width: 90%;
    height: 100%;
    padding-top: 20px;
  }

  .aboutGraphic{
    display:none;
  }

}

/*------ Landingpage START ------*/


/*------ FooterShared START ------*/

.mainFooterContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: static;
  overflow: hidden;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40vh;
  background-color: #003349;
  /* background: url('/resources/footerBack.png'); */
  background-size: contain;
}

.footerLogo {
  display: flex;
  flex-direction: row;
  margin: 30px 30px 0 30px;

}

.footerMenu{
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: solid 1px #468dac ;
}

.logoText h1 {
  color: #ffffff;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 20px;
  margin: 25px 0 0 5px;
  font-weight: 700;

}

.logoText h2 {
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 1px 0 0 5px;
  font-size: 18px;
  font-weight: 400;

}

.footerLogo img {
  height: 70px;
}

.footerDetails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 20px;
  height: 100%;
}

.footerText {
  margin: 10px 105px;
}

.footerText h2 {
  color: #ffffff;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 25px;
  margin: 0;
  font-weight: 700;
}

.footerText p{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 600;
  color: #ffffff;
}

.footerText a{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 600;
  color: #ffffff;
}

.footerContent {
  font-size: 13px;

}

.copyright {
  display: flex;
  flex-direction: column;
  align-self: end;
  width: 100%;
  height: 30px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  margin-bottom: 10px;

}

.socialIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffff;
  font-size: 30px;
  width: 50%;
}

.socialIcons i:hover {
  color: #c4c4c4;
  transition: ease 0.5s;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .mainFooterContainer {
    height: 100%;
    background-size: cover;
  }

  .footerDetails .footerText{
    padding: 20px 0;
  }

  .copyright {
    padding-bottom: 50px;
  }
}

/*------ FooterShared END ------*/


/*------ PropertiesPage START ------*/

.propertiesPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #EBECF0;
  width: 100%;
}

.propertiesPageWrapper{
  border-radius: 30px;
  margin: 20px;
  width: 95%;
}

.featuredImageContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  background: #ffffff;
  border-radius: 0 0 20px 20px;
}

.featuredImageContainer img {
  border-radius: 10px;
}

.carouselWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 97%;
}

.gridWrapper{
  width: 100%;
  margin-top: 38px;
  height: max-content;
}

.carouselWrapper > .grid-container{
  text-align: center;
  margin: auto;
  width: 55%;
  padding: 10px;
}

.image-gallery-slides {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 85vh;
  overflow: hidden;
}

/* .image-gallery-swipe img{
  align-self: center;
  align-self: center;
  justify-self: center;
  min-width: 100%;
  height: 100vh;
  overflow: hidden;
} */

.propertyTitleWrapper {
  border-radius: 20px 20px 0 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  margin-top: var(--main-header-height);
  display: flex;
  flex-direction: column;
  margin-top: var(--main-header-height);
  width: 100%;
  height: 180px;
  overflow: hidden;
  background: #ffffff;
}


.propertyTitle{
  margin: 15px 15px;
}

.propertyTitle h1 {
  display: flex;
  color: #090136;
  margin: 0 0 0 15px;
}

.propertyMenuContainer{
  background: #ffffff;
  width: 100%;
  z-index: 3;
}

.propertyMenuContainerFixed{
  width: 95%;
  top: var(--main-header-height);
  margin: 0;
  background-color: rgba(151, 151, 151, 0.384);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  position: fixed;
}

.propertyMenuContainerFixed li{
  color: rgb(32, 32, 32);

}

.propertyMenu{
  align-self: flex-start;
  width: 40%;
}

.propertyMenu a {
  width: 100%;
  text-decoration: none;
  color: #000000;
}

.propertyMenu ul{
  list-style-type: none;
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  margin: 10px 0 0 0;
  justify-content: space-between;
}

.propertyMenu li{
  text-align: center;
  width: 100%;
  height: 31px;
  padding: 0 0 0 0;
  margin: 0;
}

.propertyMenu li:hover{
  cursor: pointer;
  color: var(--neutral-item-color);
  transition: ease 0.2s;
}

.propertyMenuActive {
  color: var(--neutral-item-color);
  border-bottom: 3px var(--neutral-item-color) solid;
}

.propTypeTag{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  min-width: 70px;
  border: solid #000000 2px;
  border-radius: 10px;
  color: #000000;
  padding: 10px 15px;
  font-size: 15px;
  margin-left: 10px;
  text-align: center;
}

.propPageRating{
  color: rgb(255, 217, 0);
  font-size: 25px;
  margin: 15px 0 15px 15px;

}

.propAction {
  width: 150px;
  background-color: #ff8400;
  color: #ffffff;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 15px;
  margin-top: 15px;
}

.propAction:hover {
  background-color: #ff6600;
  transition: ease 0.3s;
  cursor: pointer;
}

.propertyInfo {
  border-radius: 0 0 10px 10px;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: space-between;
  background-color: #ffffff;
  width: 95%;
}

.propertyDesc {
  width: 100%;
  padding: 5px 0 0 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 15px;
  color: #3d3d3d;
  font-weight: 400;
}

/* .propDescText{
  border-bottom: 1px #c4c4c4 solid;
} */

.propertyMap {
  width: 100%;
  margin: 20px 0 0 0;
}

.propertyMap h1{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  margin-top: var(--main-header-height);
  margin: 15px 0 15px 15px;
  font-size: 25px;
}


.propertyInfo h1{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  margin-top: var(--main-header-height);
  margin: 15px 0 15px 15px;
  font-size: 25px;
}

.propertyAddress{
  display: flex;
  flex-direction: row;
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px #c4c4c4 solid;
  align-items: center;
  justify-content: center;
}

.addressContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.addressIcon {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.addressContent p {
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0;
}

.propertyAddress i {
  padding: 5px 5px 5px 0;
  font-size: 35px;
}

.propertySummary {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: max-content;
  margin-top: 50px;
}

.summaryMap {
  width: 90%;
  height: 200px;
  overflow: hidden;
}

.propertySumAmenities {
  width: 90%;
  border-bottom: 1px #c4c4c4 solid;
}

.propAmenitySummary{
  width: 90%;
}

.propertyAmenitiesContainer h1 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  margin-top: var(--main-header-height);
  margin: 30px 0 15px 15px;
  font-size: 25px;
}


.amenityCatItem {
  margin-top: 20px;
  margin-bottom: 10px;
}

.propertyAmenities {
  margin: 20px 0 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-template-rows: auto;
  justify-items: start;
  height: 100%;
  width: 90%;
  border-bottom: 1px #c4c4c4 solid;
}

.propertyAmenitiesWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #ffffff;
  width: 100%;
  border-radius: 10px;
  margin: 20px 0 20px 0;
  height: 100%;

}

.fullscreen{
  margin-top: 20px;
}

.bookingForm{
  border-radius: 10px;
  align-self: center;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  width: 98%;
  margin-bottom: 50px;

}

.bookingForm h1 {
  padding: 20px 0 0 20px;
  font-family: 'Roboto Slab', serif;
  font-size: 25px;
}

.roomTypeItemsContainer {
  border-radius: 10px;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0 20px 0;
}

.propBackLink {
  padding: 10px 5px;
}

.propBackLink i{
  height: 100px;
  padding: 10px 5px;
  font-size: 15px;
}

.roomTypeItemsContainer h1 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  margin-top: var(--main-header-height);
  font-size: 25px;
  margin: 15px 0 15px 15px;
  padding: 0;
}

.roomItems {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.roomItemContainer {
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  width: 100%;
  height: 200px;
  margin: 5px 0 5px 0;
  overflow: hidden;
}

.roomItemContainer h1 {
  margin: 0;
}

.roomItemImage{
  margin: 10px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;;
  height: 90%;
  width: 500px;
  overflow: hidden;
}

.roomItemImage img{
  display: block;
  width: 100%;
}

.roomInfo{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
}

.roomInfo h1{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  margin-top: var(--main-header-height);
  margin:0;
  width: 100%;
  font-size: 20px;
}

.roomSummary {
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 30%;
  margin-top: 10px;
}

.roomInfoItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.roomInfoItem  i{
  font-size: 18px;
  margin: 15px 0;
}

.roomInfoItem  p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 15px;
  margin: 15px 5px;
  color: #797979;
}

.detailItem{
  display: flex;
  flex-direction: row;
  width: 100px;
}


.detailItem .svg-inline--fa {
  margin: 12px 5px 0 0;
  padding: 0;
}

.detailItem {
  width: 100%;
}

.detailItem p {
  color: #797979;
  font-size: 13px;
  margin: 13px 0;
  margin: 12px 0;
  width: 300px;
}

.propAmenityGroup {
  width: 100%;
  border-radius: 10px;;
}

.image-gallery-icon {
  z-index: 2;
}

image-gallery-image {
  padding: 0;
  margin: 0;
}

.detailFeaturedImage {
  width: 50%;
  height: 500px;
  background-color: #ffffff;
}

.roomTitleWrapper {
  border-radius: 20px 20px 0 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  margin-top: var(--main-header-height);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: var(--main-header-height);
  width: 100%;
  height: 180px;
  overflow: hidden;
  background: #ffffff;
}


.roomDetailSummary {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding-left: 10px;
}

.roomDetailAmenities {
  padding-left: 10px;

}

.roomDetailAmenities .detailItem {
  width: 100%;
  display: flex;
  flex-direction: row;

}

.roomDetailAmenities .detailItem p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 15px;
  margin: 15px 5px;
  color: #797979;
}

.roomDetailAmenities .detailItem  i{
  font-size: 18px;
  margin: 15px 0;
}

.roomDetailInfo{
  border-radius: 10px ;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-wrap:wrap;
  justify-content: space-between;
  background-color: #ffffff;
  width: 100%;
  min-height: 10vh;
}

.detailButtonRow {
  padding: 10px;
}

.propertyTransport {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  height: 200px;
  border-top:solid 1px #c4c4c4;
}

.transportWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.propertyTransport i{
  font-size: 50px;
}

.propertyTransport h2 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}


.transportItems {
  margin-bottom: 60px;
}

.transportItem {
  padding: 5px;
  width: 100%;
}

.transportImageContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.transportItem h3{
  width: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 10px;
  margin: 0;
  padding: 0;
  text-align: center;

}

.transportItem p{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: gray;
  font-size: 10px;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.transportItem img {
  width: 50px;
}

.transportElements {
  /* display: flex;
  flex-direction: row;
  justify-content: space-around; */
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  align-items: center;
  justify-items: center;
  width: 450px;
  padding: 0 5px;
}

.overviewSection{
  height: max-content;

}

@media only screen and (max-width: 800px) {

  .roomInfoItem {
    max-width: 60%;
  }

  .overviewSection{
    height: max-content;
  }

  .propertyBanner {
    height: max-content;
  }

  .summaryMap {
    height: 200px;
    width: 100%;
  }

  .transportElements{
    display: flex;
    flex-direction: row;
    width: 350px;
    margin-top: 15px;
  }

  .transportWrapper {
    padding: 0;
    margin: 0;
    height: 80px;
  }

  .carouselWrapper {
    display: flex;
    flex-direction: column;
    height: max-content;
    width: 97%;
  }

  .propertyAmenities {
    margin: 20px 0 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-template-rows: auto;
    justify-items: start;
    height: 100%;
    width: 100%;
    border-bottom: 1px #c4c4c4 solid;
  }

  .propertyTitleWrapper {
    border-radius: 20px 20px 0 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  margin-top: var(--main-header-height);
    display: flex;
    flex-direction: column;
    margin-top: var(--main-header-height);
    width: 100%;
    height: 210px;
    overflow: hidden;
    background: #ffffff;
  }

  .featuredImageContainer{
    width: 100%;
    height: 175vh;
    background: #ffffff;
  }

  .featuredImageContainer .image-gallery-slides {
    height: 34vh;
    overflow: hidden;
  }

  .roomItemContainer {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 100%;
    height: 450px;
    margin: 5px 0 5px 0;
    overflow: hidden;
  }

  .roomInfo{
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 45%;
  }

  .roomSummary {
    margin: 10px 20px 0 30px;
    width: 100%;
  }

  .roomInfo h1 {
    margin: 10px 20px 0 10px;
  }

  .roomItemImage{
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .roomItemImage img{
    display: block;
    width: 105%;
    height: 105%;
  }

  .propertyInfo {
    border-radius: 10px ;
    align-self: center;
    display: flex;
    flex-direction: column;
    flex-wrap:wrap;
    justify-content: space-between;
    background-color: #ffffff;
    width: 100%;
    height: max-content;
    padding: 0;
  }

  .propertyDesc {
    width: 90%;
    margin: 5px 20px;
    padding-top: 0;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 18px;
    color: #3f3f3f;
    height: max-content;
  }

  .propertySummary {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 450px;
  }

  .propTypeTag {
    height: max-content;
  }

  .propertyMenuContainerFixed{
    width: 95%;
    top: var(--main-header-height);
    margin: 0;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    position: fixed;
    z-index: 3;
  }

  .propertyMenu{
    align-self: flex-start;
    width: 100%;
  }

  .propAmenitySummary{
    width: 100%;
  }

  .image-gallery-icon {
    z-index: 2;
  }

}

/*------ PropertiesPage END ------*/

/*------ Amenities START --------*/

.AmenitiesIconCheckbox{
  display: flex;
  flex-direction: row;
}


/*------ StayPage START ------*/

.stayContainer {
  margin-top: var(--main-header-height);
  background: #EBECF0;
  padding-top: 20px;
  height: 100%;
}

.stayWrapper{
  height: 100%;
  margin: 20px 5px 0 5px;
  padding: 25px 0;
  text-align: center;
}

.filterBar {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 10px;
  margin: 10px 10px 10px 10px;
  padding: 10px;
}

.searchBar {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.searchBar input{
   width: 80%;
}

.filterSection {
  display: flex;
  flex-direction: column;
  height: 65px;
  justify-content: flex-end;
  margin-left: 10px;
}

.filterButton {
  background-color: var(--neutral-item-color);
  color: #ffffff;
  font-weight: 600;
}

.filterButton:hover {
  background-color: var(--neutral-hover-color);
}

.filterCard{
  background-color: #ffffff;
  position: absolute;
  left: 41%;
  margin-top: 70px;
  height: 300px;
  width: 400px;
  border-radius: 10px;
  border: royalblue solid 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow: hidden;
  z-index: 10;
}

.filterCard h3 {
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.filterItems {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 10px;
}

.filterItems div{
  margin: 5px 0;
}

.ratingFilter {
  padding: 5px;
  width: 50%;
  border-right: #dbdbdb solid 1px;
}

.atollFilter {
  padding: 5px;
  width: 50%;
  overflow-y: scroll;
}

.filterBar label {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-left: 10px;
}

.stayFilter {
  width: 50%
}

@media only screen and (max-width: 800px) {
  .stayFilter {
    width: 85%
  }

  .searchBar {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

}


/*------ StayPage END ------*/

/*------ Amenities START --------*/

.AmenitiesIconCheckbox label{
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 400;
}
.AmenitiesIconCheckbox input {
  position: relative;
  cursor: pointer;
  height: 17px;
  width: 17px;
}

.amenitiesh1 {
  position: relative;
  padding-left: 45px;
}

/*------ Amenities END --------*/


/*------ RoomDetails START ------*/

.roomPageContainer {
  min-height: 100vh;
  background: #EBECF0;
  margin-top: var(--main-header-height);
  padding: 10px;
}

.roomPageWrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  height: 100%;
}


.fullscreen{
  margin-top: 50px;
  margin-left: 20px;
  width: 100vw;
  align-self: center;
  justify-self: center;

}



/* .roomImageWrapper img {
  border-radius: 10px;
  width: 100%;
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-content: center;
  border: none;
} */

.image-gallery-image {
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  border: none;


}

.roomImageWrapper .image-gallery-content {
  width: 100%;
  overflow: hidden;
  align-self: center;
  justify-self: center;
  border: none;

}


.image-gallery-slide img{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  border: none;


}

.roomImageWrapper .image-gallery-svg {
  padding: 5px;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  margin: 5px 30px;
  border: none;

}

.roomImageWrapper.image-gallery-swipe img {
  align-self: center;
  align-self: center;
  justify-self: center;
  min-width: 100%;
  height: 100vh;
  overflow: hidden;
  border: none;

}

.roomInformationWrapper{
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 20px 20px 20px;
}

.bookingButton {
  margin-left: 10px;
  color: #1C6E74;
  font-weight: 600;
  background-color: #ffffff;
  background-color: none;
  border: solid 2px #1C6E74;
  border-radius: 5px;
  padding: 10px;
}

.cancelBooking {
  margin-left: 10px;
  color: var(--negative-item-color);
  font-weight: 600;
  background-color: #ffffff;
  border: solid 2px var(--negative-item-color);
  border-radius: 5px;
  padding: 10px;
}

.cancelBooking:hover {
  cursor: pointer;
  transition: ease 0.5s;
  background-color: var(--negative-item-color);
  color: #ffffff;
}

.bookingButton:hover {
  cursor: pointer;
  transition: ease 0.5s;
  background-color: #1C6E74;
  color: #ffffff;
}

.roomAmenitiesSection {
  width: 100%;
}


.roomInformationWrapper h2 {
  width: 100%;
  margin-left: 25px;
}

.roomInformationHighlights {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #c4c4c4;

}

.roomInfoItems{
  display: flex;
  flex-direction: row;
}

.featuredItem{
  display: flex;
  flex-direction: row;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.featuredItem svg {
  color: #3b3b3b;
  font-size: 15px;
  margin: 11px 5px 10px 10px;
}

.featuredItem p {
  color: #797979;
  font-size: 15px;
  margin: 10px 0;
}

.roomFeaturedAmenities {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.highlightItem {
  display: flex;
  flex-direction: row;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.highlightItem:nth-child(2){
  margin: 0 5px;
}

.highlightItem i {
  color: #3b3b3b;
  font-size: 20px;
  margin: 11px 10px 12px 10px;
}

.highlightItem p {
  color: #797979;
  font-size: 15px;
  margin: 13px 0;

}


.amenityCatItem h3 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin: 0;
  font-size: 15px;
  margin-left: 10px;
}

.amenityItem {
  display: flex;
  flex-direction: row;
  clear: both;
  margin:10px;


}

.amenityItem .svg-inline--fa {
  width: 20px;
  margin: 0;
  padding-right: 5px;
}

.amenityItem p {
  color: #797979;
  font-size: 11px;
  margin: 0;
}

.roomDetailButtonRow {
  width: 100%;
  margin-top: 20px;
}

.roomDesc {
  width: 100%;
  margin: 12px 10px 12px 10px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 15px;
  color: #3d3d3d;
  font-weight: 400;
}

.moreInfoRow {
  padding-top: 30px;
  height: 100px;
}

.moreInfo{
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  width: 100px;
  height: 0px;
  border: solid #1C6E74 2px;
  overflow: hidden;
}

.moreInfo p:hover{
  color: white;
}

.moreInfoContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #1C6E74;
  font-weight: 600;
  width: 100%;
  padding: 20px 0;
}

.moreInfoContent:hover{
  color: #ffffff;
  background-color: #1C6E74;
  transition: ease;

}


@media only screen and (max-width: 800px) {


  .moreInfoRow {
    padding: 10px 0 0 0;
  }

  .amenityHeading h2{
    text-align: left;
    margin-left: 20px;
    max-width: 90%;

  }

  .roomDesc {
    width: 80vw;
    margin: 12px 10px 12px 10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 15px;
    color: #3d3d3d;
    font-weight: 400;
  }

  .roomImageWrapper {
    margin-right: 10px;
    overflow: hidden;
  }

  .roomImageWrapper .image-gallery-content {
    width: 90vw;
    overflow: hidden;
    border: none;

  }

  .roomImageWrapper .image-gallery-image {
    align-self:center ;
    justify-self: center;
    border: none;
    overflow: hidden;


  }

  .roomImageWrapper .image-gallery-slides {
    height: 34vh;
    overflow: hidden;
    border: none;


  }

  .roomImageWrapper .image-gallery-slides img{
    min-width: 100%;
    border: none;
    overflow: hidden;

  }

  .image-gallery-content .left .fullscreen {
    display: none;
    overflow: hidden;

  }

  .roomPageContainer {
    max-height: 400vh;
    background:  #EBECF0;
    margin-top: var(--main-header-height);
    padding: 10px;
  }

  .roomImageContainer{
    width: 100%;
    height: 60vh;
    background: #ffffff;
    justify-content: flex-start;
    overflow: hidden;

  }

  .roomPageWrapper {
    background-color: #ffffff;
    border-radius: 10px;
    min-height: 150vh;
  }

  .roomImageWrapper {
    width: 100%;
    height: 44vh;
  }

  .roomInformationWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 0 0 10px;
  }

  .image-gallery-slides{
    height: 50vh;
    overflow: hidden;

  }

}



/*------ RoomDetails END ------*/

/*------ BookingForm START ------*/

.bookingFormContainer {
  display: flex;
  flex-direction:column;
  padding: 10px;
}

.closeBar i:hover {
  color:gray;
  cursor: pointer;
  transition: ease 0.5s;
  border-bottom: 1 solid c4c4c4;
}

.bookingFormContainer span{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #085373;
  font-size: 30px;
}

.bookingForm {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border: 1px solid #c4c4c4;
  padding: 10px;

}

.bookingForm {
  color: rgb(83, 83, 83);
}

.bookingForm textarea {
  resize: none;
}

.bookingFormFull {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bookingForm label{
  margin-left: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: gray;
}

.bookingFormRow {
  display: flex;
  flex-direction: row;
}

.bookingFormButtonRow button {
  margin: 10px;
  align-self: flex-end;
}


.bookingFormHalf {
  display: flex;
  flex-direction: column;
  width: 50%;

}

.budgetInput {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="70" height="30"><text x="5" y="24" style="color:gray; font: 16px Trebuchet MS;">US$</text></svg>') no-repeat;
    font-size: 14px;
    padding-left: 35px;
    width: 43.3%;
}

.bookingFormTri {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.bookingFormButtonRow {
  display: flex;
  flex-direction: row;
  width: 100%
}

/* .bookingModal {
  background: #ffffff;
  overflow: scroll;
} */


.bookingFormModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(150deg, #f9fcff 2%, #e1fdf885 50%);
  margin: 30px 30px;
}

.bookingFormOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow-y: scroll;
  height: 100vh;
  background-color: #ffffff;
}

@media only screen and (max-width: 800px) {
    .closeBar i {
      font-size: 25px;
    }

    .bookingFormRow {
      display: flex;
      flex-direction: column;
    }

    .bookingFormHalf {
      width: 100%;
    }

    .bookingFormTri {
      width: 100%;
    }

    .budgetInput {
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="70" height="30"><text x="5" y="24" style="color:gray; font: 16px Trebuchet MS;">US$</text></svg>') no-repeat;
      font-size: 14px;
      padding-left: 35px;
      width: 78%;
  }

  .bookingFormButtonRow {
    display: flex;
    flex-direction: row;
    width: 100%
  }
}

/*------ BookingForm END ------*/

/*------ Transport START ------*/

.transportContainer {
  margin-top: var(--admin-header-height);
}

.addRow {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 600;
}

.transportInput {
  width: 90%;
}

.transportFormColumn {
  min-width: 400px;
}
.transportSlugColumn{
  max-width: 300px;
  min-width: 200px;
  overflow-y: scroll;

}

.transportInputContainer {
  margin: 10px 10px;
  border-radius: 5px;
  height: 500px;
  border: 2px solid #c4c4c4;
}

.transportSelectionContainer {
  margin: 10px;
}

.transportSelectionContainer label {
  margin: 0;
}

.transportSelectArea {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: 50px;
  margin: 10px 5px;
}

.transportSelectElement {
  min-width: 60px;
  min-height: 20px;
  max-height: 30px;
  text-align: center;
  margin: 5px;
  border-radius: 5px;
  background-color: #dadada;
  color: #000000;

}

.transportSelectElement:hover {
  background-color: #c4c4c4;
  cursor: pointer;
  transition: ease 0.5s;

}

.transportSelectElement p {
  margin: 5px;
  font-family: var(--font-family);
}

.transportSelectedArea {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
}

.transTypeList {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* padding: 0 20px 0 20px;
  margin: 10px 20px 10px 20px; */
  background: #ffffff;
  border-radius: 10px;
}

.transTypeList table{
  font-family: var(--font-family);
  justify-content: space-between;
  margin: 10px 10px;
  width: 100%;
  border-collapse: collapse;
}

.transTypeList tr {
  width: 100%;
  color: #747a80;;

}

.transTypeList tr:hover {
  background-color: #fafafa;
  cursor: pointer;
}

.transTypeList th {
  text-align: start;
  color: #000000;
  padding: 10px 0 10px 0;

}

.transTypeList td {
  border-top: #e4e4e4 solid 1px;
  padding: 10px 0 10px 0;
  font-size: 15px;
  height: 50px;

}

.transportActions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20px;
}

.transportActions div{
  display: flex;
  flex-direction: row;
  min-width: 20px;

}

.transportActions i{
  padding: 0 5px;
}

.ri-checkbox-circle-line {
  color: var(--positive-item-color);
}

.ri-checkbox-circle-line:hover {
  font-size: 20px;
  transition: ease 0.5s;
  color: var(--positive-hover-color);
}

.transportInputCancel .ri-close-circle-line {
  color: var(--negative-item-color);
}

.transportInputCancel .ri-close-circle-line:hover {
  font-size: 20px;
  transition: ease 0.5s;
  color: var(--negative-hover-color);
}

.durationColumn {
  width: 20px;
  align-items: flex-start;
}
/*------ Transport END ------*/
/*------ MaldivesPage START ------*/
.maldives-header{
  margin-top: var(--main-header-height);
  font-size: 60px;
  color: white;
  padding-top: 10px;
  text-align: center;
}

.geography-image {
  background-image: url("/resources/maldives-geography.jpg");
  background-color: #cccccc;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
}

.culture-image {
  background-image: url("/resources/maldives-culture.jpg");
  background-color: #cccccc;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
}

.ontop-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  color: white;
  background-color: #063847a8;
  border-radius: 20px;
}
.ontop-text h1{
  text-align: center;
  font-size: 20px;
  padding-top: 20px;
}

.ontop-text h3{
  text-align: center;
  font-size: 20px;
}
.ontop-text p{
  font-size: 16px;
  padding-left: 15px ;
  padding-right: 15px;
  text-align: justify;
}

.environment-image {
  background-image: url("/resources/maldives-environment.png");
  background-color: #cccccc;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
}
@media only screen and (max-width: 800px) {
  .maldives-header{
    margin-top:var(--main-header-height);
    font-size: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .geography-image{
    height: 115vh;
  }

  .ontop-text h1{
    margin-top: 2px;
    text-align: center;
    font-size: 20px;
  }

  .ontop-text {
    width: 90%;
    border-radius: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  

  .ontop-text h3{
    text-align: center;
    font-size: 15px;
  }

  .ontop-text p{
    margin-top: 1px;
    font-size: 15px;
    padding: 0px 10px ;
    text-align: left;
  }
}

/*------ MaldivesPage END ------*/

/*------ AboutUSPage START ------*/

.aboutus-header{
  margin-top: var(--main-header-height);
  font-size: 50px;
  color: white;
  padding-top: 1.5%;
  margin-bottom: 5px;
  text-align: center;
}
.aboutus-wrapper{
  background-image: url("/resources/aboutus-1.jpg");
  background-color: #cccccc;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.aboutus-goals-wrapper{
  background-image: url("/resources/aboutus-2.jpg");
  background-color: #cccccc;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.aboutus-ontop-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  color: white;
  background-color: #063847a8;
  border-radius: 20px;
}

.aboutus-ontop-text h1{
  text-align: center;
  font-size: 22px;
}

.aboutus-ontop-text h3{
  text-align: center;
  font-size: 20px;
}
.aboutus-ontop-text p{
  font-size: 18px;
  padding-left: 15px ;
  padding-right: 15px;
  text-align: justify;
}

.main-div-goals{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.goals-ontop-text {
  width: 600px;
  text-align: left;
  color: white;
}

.goals-ontop-text p{
  font-size: 20px;
  padding: 10px ;
  text-align: left;
}

.goals-icon{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 30px;
  color:#ffffff;
  align-items: center;
  background-color: #063847a8;
  border-radius: 20px;
}

.icon-controller{
  width: 100px;
  height: 100px;
  margin: 20px;
  color: white;
}

@media only screen and (max-width: 800px) {
  .aboutus-header{
    margin-top: var(--main-header-height);
    font-size: 35px;
    color: white;
    padding-top: 10%;
    margin-bottom: 10px;
  }

  .aboutus-ontop-text{
    width: 90%;
    border-radius: 20px;
    margin-top: 100px;
    margin-bottom: 5px;
  }

  .aboutus-ontop-text h3{
    text-align: center;
    font-size: 16px;
  }

  .aboutus-ontop-text p{
    margin-top: 1px;
    font-size: 15px;
    padding: 0px 10px ;
    text-align: left;
  }

  .goals-ontop-text {
    font-size: 20px;
    width: 300px;
    text-align: left;
    color: white;
  }

  .icon-controller{
    width: 60px;
    height: 60px;
    margin: 10px;
  }

  .goals-ontop-text p{
    font-size: 17px;
    padding: 10px ;
    text-align: justify;
  }

  .goals-ontop-text h2{
    text-align: center;
  }

  .goals-icon{
    margin: 50px;
    flex-direction: column;
    width: 85%;
  }
}
/*------ AboutUsPage END --------*/

/*------ PropertyFeature START --------*/

.propertyFeature{
  background-color: #ffffff;
  height: max-content;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;

}

.imageGrid {
  width: 50%;
}

.propertyOverview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  width:50%;
}

.roomImageGrid{
  width: 60%;
}

.roomImageWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  height: max-content;
  overflow: hidden;
}

.roomIntro{
  width: 50%;
  padding: 0 20px 0 0 ;
}

.roomIntro h2 {
  margin-left: 10px;
}

.roomDetailSection {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: max-content;
}

.roomAmenitiesList{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 25px 0;
  border-top: solid #c4c4c4 1px;
}

.amenityHeading h2{
  text-align: left;
  width: 100%;
}

.amenityElements {
  margin: 20px 0 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-template-rows: auto;
  justify-items: start;
  height: 100%;
  width: 100%;
}



@media only screen and (max-width: 800px) {
  .roomIntro{
    width: 100%;
  }


  .amenityElements {
    margin: 20px 0 20px 0;
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 10%;
    height: 100%;
    width: 100%;
  }
  
  .roomImageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    width: 100vw;
    height: max-content;
    overflow: hidden;
  }

  .roomImageGrid{
    width: 100%;
  }

  .propertyFeature{
    background-color: #ffffff;
    height: max-content;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
  }

  .imageGrid {
    width: 100%;
  }
  
  .propertyOverview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    width:100%;
  }

  .propertyTransport{
    margin-top: 50px;
  }

  .summaryMap {
    width: 95%;
  }

  .propertyDesc{
    width: 100%;
    margin: 10px 0;
  }
}



/*------ PropertyFeature END --------*/



/*-------CardsLandingPage START-------*/

.Cards_Section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url("/resources/landing-cards-bg.jpeg");
  background-position: center;
  background-size: cover;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 40px;
  padding-right: 40px;
}

.card {
  display: block;
  position: relative;
  align-items: center;
  height: 400px;
  width: 250px;  
  background-color: #f1f1f1;
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  text-decoration: none;
}

.card img {
  width: 100%;
  height: auto;
  
}

.card__overlay {
  position: absolute;
  height: 230px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #edfbfd; 
  border-radius: 30px 30px 0 0;
}

.card__header {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 30px 30px 0 0;    
  background-color: #edfbfd;
}



.card__title {
  font-size: 20px;
  padding: 0;
  margin: 5px;
}

.card__description {
  padding: 0 15px 15px 15px;
  margin: 0;
  font-size: 13px;
  overflow: hidden;
}

.aboutCardAction {
  background: none;
  color: #ffffff;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 18px;
  border:#ffffff solid 2px;
}
.aboutCardAction:hover {
  background-color: #ffffff;
  color: #000000;
  transition: ease 0.3s;
  cursor: pointer;
}

@media only screen and (max-width: 800px){
  .Cards_Section{
    height: auto;
    background-image: url("/resources/cards-bg-portraint.jpg");
  }
  .card{
    width: 350px;
    height: 450px;
    border-radius: 30px;
    margin-bottom: 20px;

  }
  .cards{
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .aboutTextWrapper{
    padding-left: 40px;
  }
  .aboutTextWrapper h1 {
    width: 100%;
    font-size: 40px;
    
  }
  
  .aboutTextWrapper p {
    font-size: 18px;
    text-align: justify;
  }
  .card__description {
    font-size: 15px;
    text-align: justify;
  }
}
/*----CardsLandingPage END-----*/


/*----Packages START-----*/

.packageRoomSection {
  border-radius: 20px;
  height: max-content;
  margin: 0 10px;
}


.packageRoomSection h1 {
  margin: 10px 2px;
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 600;
}

.packageRoomSection i:hover {
  cursor: pointer;
}

.addNewRoom {
  transition: ease 0.5s;
  border: solid #c4c4c4 2px;
  background-color: #eeeeee;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 80px;
}

.addNewRoomExpanded{
  transition: ease 0.5s;
  border: solid #c4c4c4 2px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 200px;
}

.addRoomContent{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 18%;
  font-size: 50px;
}

.addRoomContent h1 {
  font-size: 20px;
}

.roomSelect {
  width: 100%;
}

.addRoomButtonRow {
  margin-left: 10px;
}

.roomListArea{
  height: max-content;
  margin-bottom: 10px;
}

.roomListItem{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 98%;
  height: 60px;
  margin: 10px 10px;
  background-color: #eeeeee;
  padding: 0 10px;
  font-size: 20px;
  border-radius: 10px;
}

.roomListItem i {
  margin-right: 10px;
  color: var(--negative-item-color);
  cursor: pointer;
  transition: ease 0.5s;

}

.roomListItem i:hover {
  margin-right: 10px;
  color: var(--negative-hover-color);
}

.packageInputWrapperFull {
  width: 100%;
  height: max-content;
  margin: 10px 10px;
}

.packagePrice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ff6600;
  height: 40px;
  width: 80px;
  border-radius: 0 10px 10px 0;
}

.packagePrice p{
  font-size: 15px;
  color: #ffffff;
  margin: 0;
}

.packPropName p{
  font-size: 15px;
}

.packageContainer {
  width: 100vw;
  height: max-content;
  background-color: #EBECF0;
}

.packageContent {
  margin-top: var(--main-header-height);
  padding: 30px;
}

.packageHero {
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
}

.packageTitle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 30px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:#000000;
}

.packageTitleFixed{
  width: 95.4%;
  top: var(--main-header-height);
  margin: 0;
  background-color: rgba(151, 151, 151, 0.384);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 99;
  color: #ffffff;
}

.packageTitle h1 {
  margin: 10px 0;
  font-size: 30px;
}

.packageTitle h2 {
  margin: 10px 0 ;
  font-size: 20px;
}

.packPropTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: max-content;
  align-items: center;
  margin-left: 10px;
}

.packPropTitle p {
  margin: 0 20px;
  border: solid #000000 2px;
  padding: 5px;
  border-radius: 5px;
}

.packageDetPrice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ff6600;
  height: 40px;
  width: 80px;
  border-radius: 10px;
  color: #ffffff;
}

.packageDetails {
  display: flex;
  flex-direction: row;
}

.packageDesc {
  margin: 20px 10px;
  font-size: 20px;
}

.packageInfo {
  width: 50%;
}

.packageRooms {
  margin: 10px 0;
  overflow: hidden;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.packageRooms h1 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:#000000;
  font-size: 30px;
}

.packageRoomItem {
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  margin: 10px 0;
  cursor: pointer;
  transition: ease 0.5s;
  height: 70px
}

.packageRoomItemExp {
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  margin: 10px 0;
  transition: ease 0.5s;
  height: max-content;
}

.roomTitle {
  margin: 0 10px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.roomTitle h1 {
  margin: 0;
}

.roomTitle i {
  font-size: 30px;
  cursor: pointer;
}

.roomContent {
  display: flex;
  flex-direction: row;
}

.packageOverview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 35%;
}

.packageActionRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.packageActionRow button {
  height: max-content;
}

.formTitle h2{
  font-size: 20px;
}

.formTitle h2 span{
  font-size: 30px;
  color: #1C6E74;
}

.carouselFilter h3{
  margin-left: 50px;
}

@media only screen and (max-width: 800px){

  .packageDetails {
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
  }

  .packageAmenities {
    width: 90%;
  }

  .packageRoomItem {
    background-color: #ffffff;
    border-radius: 10px;
    width: 100%;
    margin: 10px 0;
    cursor: pointer;
    transition: ease 0.5s;
    height: 90px
  }

  .packPropTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-left: 10px;
  }

  .packageInfo {
    width: 100%;
  }

  .packageDesc {
    margin: 20px 10px;
    font-size: 20px;
  }

  .packageContent {
    margin-top: var(--main-header-height);
    padding: 20px;
  }

  .roomContent {
    display: flex;
    flex-direction: column;
  }

  .packageActionRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: start;
  }

  .packageOverview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .packageOverview h2 {
    font-size: 15px;
  }

  .packageDetPrice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ff6600;
    height: 40px;
    width: 60px;
    border-radius: 10px;
    color: #ffffff;
  }

  .packageTitle button {
    margin-left: 0;
  }

}

/*----Packages END-----*/




